

.text-primary-graviti-tag,
.text-danger-graviti-tag,
.text-dark-graviti-tag,
.text-info-graviti-tag {
  color: #FFF !important;
}

.text-secondary-graviti-tag,
.text-warning-graviti-tag,
.text-light-graviti-tag {
  color: #192C4E !important;
}

.text {
  font-size: 14px;
}