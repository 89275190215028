.preview {
  width: 100%;
  height: 200px;
  border: dotted;
  border-color: #a7a7a775;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.preview img {
  height: 190px;
  width: 100%;
  object-fit: cover;
}
