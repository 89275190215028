.ModalII {
  padding: 0;
}

.ModalII__header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 15px;
}

.ModalII__header-title {
  padding: 15px 0 0 25px;
}

.ModalII__header-close {
  width: 50px;
  height: 50px;
  border-width: 0 0 2px 2px;
  border-color: #132C51;
  border-style: solid;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ModalII__header-close:hover {
  background-color: #132C51;
  border: 2px solid #132C51;
  color: #fff;
  cursor: pointer;
}

.popup-content {
  padding: 0 !important;
}

.ModalII__carousel {
  margin-bottom: 5px;
}

.ModalII__upload {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
} 

.ModalII__footer {
  display: flex;
  justify-content: flex-end;
  padding: 0 25px 15px 25px;
}

.popup-overlay {
  overflow-x: scroll;
}

.nav {
  padding: 0 25px 0 25px;
}

.tab-content {
  padding: 15px 25px 0 25px;  
}

.ModalII__carousel_image {
  height: 250px;
  object-fit: contain;
}