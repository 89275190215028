.bar-item {
  border: 1px #2a5697 solid;
  margin: 0 1px;
  padding-top: 15px;
  width: 10rem;
  font-size: 0.9rem;
  text-align: center;
}
.bar-item__active {
  background-color: #132c51;
}
.bar-item__active_green {
  background-color: #34ad63;
  border-color: #34ad63;
}
