.Modal {
  padding: 0;
}

.Modal__header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 15px;
}

.Modal__header-title {
  padding: 15px 0 0 25px;
}

.Modal__header-close {
  width: 50px;
  height: 50px;
  border-width: 0 0 2px 2px;
  border-color: #132C51;
  border-style: solid;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Modal__header-close:hover {
  background-color: #132C51;
  border: 2px solid #132C51;
  color: #fff;
  cursor: pointer;
}

.Dates, .NewDates, .approved-date {
  margin: 0 25px 15px 25px;
}

.Dates {
  display: flex;
  justify-content: space-evenly;
  margin: 25px;
}

.Dates__Item {
  width: 200px;
  height: 100px;
}

.Dates__Item label {
  cursor: pointer;
}

.NewDates {
  display: flex;
  justify-content: space-between;
}

.NewDates .form-group {
  width: 45%;
}

.subtitle {
  margin: 0px 25px;
}

.outer
{
  width:100%;
  text-align: center;
}
.inner
{
  display: inline-block;
}
.display-flex
{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.display-flex-block
{
  display: flex;
  align-items: center;
  flex-direction: row;
  width:100%;
  table-layout: fixed;

}

.buttons_tittle {
  width: 100%;
}
.active_btn{
  color: #fff !important;
  background-color: #132C51!important;;
  border-color: #DCDADA !important;
}
.inactive_btn{
  color: #000000 !important;
  background-color: #fff!important;;
  border-color: #DCDADA !important;;
}