.box-month {
  padding: 30px;
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  display: inline-block;
}

.link-invoices {
  font-size: 10px;
  margin: 5px;
  font-family: 'Open Sans', sans-serif;
  color: #132c51;
  cursor: pointer;
}