.thumbs-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 16
}
.thumbs-container > *+* {
  margin-left: 16px
}

.thumb {
  display: inline-bock;
  border-radius: 2;
  border: 1px solid #eaeaea;
  margin-bottom: 8;
  margin-right: 8;
  width: 100px;
  height: 100px;
  padding: 4;
  position: relative
}

.thumb-close {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  height: 22px;
  width: 22px;
  border: none;
  border-radius: 16px;
  text-align: center;
  padding: 0;
  line-height: 0;
}

.thumb-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}