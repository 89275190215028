.bar-item-progress__active {
  background-color: #192C4E;
}
.bar-item-progress__active_green {
  background-color: #02CCA7;
  border-color: #02CCA7;
}

.text-container {
  font-size: 0.6rem;
  text-align: center;
  line-height: normal;
}

.border-primary {
  border-color: #192C4E !important;
}