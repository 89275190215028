.tab-section-selected {
  font-family: poppins;
  color: #192C4E;
  font-weight: 800;
}
.tab-section-unselected {
  font-family: poppins;
  color: #192C4E;
}
.separator {
  color: #E9E9E9;
  border-top: 1px solid;
  margin-bottom: 3%;
}
