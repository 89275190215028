.side-menu {
  background-color: white;
  list-style: none;
  padding: 15px;
  min-height: 640px;
  width: 100%;
}

.side-menu li {
  padding: 15px;
  cursor: pointer;
  color: #132c51;
}

.side-menu-drop {
  margin-left: 10px;
  font-size: 12px;
}

.side-menu li:hover {
  background-color: #d8d8d86e;
}

.length-list {
  float: right;
}

.item-selected {
  background-color: #d8d8d86e;
  font-weight: bold;
}

@media only screen and (max-width: 1024px) {
  .side-menu {
    background-color: white;
    list-style: none;
    padding: 10px;
    height: 100%;
    width: 100%;
  }

  .side-menu li {
    padding: 12px;
    cursor: pointer;
    color: #132c51;
    font-size: 12px;
  }
}

@media only screen and (max-width: 768px) {
  .side-menu {
    background-color: white;
    list-style: none;
    padding: 10px;
    height: 100%;
    width: 100%;
  }

  .side-menu li {
    padding: 12px;
    cursor: pointer;
    color: #132c51;
    font-size: 12px;
  }
}

@media only screen and (max-width: 580px) {
}

@media only screen and (max-width: 320px) {
}
