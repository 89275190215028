.grid-gallery {
  display: grid;
  grid-auto-rows: 80px;
  grid-gap: 1rem;
  grid-auto-flow: row dense;
}

@media all and (min-width: 320px) {
  .grid-gallery {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media all and (min-width: 768px) {
  .grid-gallery {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media all and (min-width: 1024px) {
  .grid-gallery {
    grid-template-columns: repeat(6, 1fr);
  }
}

.grid-gallery__item {
  border: none;
}

.grid-gallery__item:nth-child(11n+1) {
  grid-column: span 1;
}

.grid-gallery__item:nth-child(11n+4) {
  grid-column: span 2;
  /* grid-row: span 1; */
}

.grid-gallery__item:nth-child(11n+6) {
  grid-column: span 3;
  /* grid-row: span 1; */
}

.grid-gallery__item:nth-child(11n+7) {
  grid-column: span 1;
  /* grid-row: span 2; */
}

.grid-gallery__item:nth-child(11n+8) {
  grid-column: span 2;
  /* grid-row: span 2; */
}

.grid-gallery__item:nth-child(11n+9) {
  grid-column: span 3;
  /* grid-row: span 3; */
}

.grid-gallery__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
