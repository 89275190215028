.autocomplete-container {
    display: flex;
    flex-direction: column;
    max-height: 300px;
    overflow: hidden;
}

.input {
    min-height: 45px;
    border: none;
    color: black;
    background-color: #a7a7a775;
    text-indent: 10px;
    width: 100%;
    margin: 5px;
    font-family: 'Open Sans', sans-serif;
}

.input::placeholder {
    color: rgb(99, 93, 93);
    font-style: italic;
}

.option-list {
    background-color: #f4f4f4;
    padding: 10px;
    border-radius: 5px;
    text-indent: 10px;
    color: gray;
    font-family: 'Source Code Pro', monospace;
    font-weight: 600;
    cursor: pointer;
    margin-bottom: 10px;
    overflow-y: auto;
}