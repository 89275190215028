.Modal {
  padding: 0;
}

.Modal__Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.Modal__Header-title {
  padding: 15px 0 0 25px;
}

.Modal__Header-close {
  width: 50px;
  height: 50px;
  border-width: 0 0 2px 2px;
  border-color: #132c51;
  border-style: solid;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Modal__Header-close:hover {
  background-color: #132c51;
  border: 2px solid #132c51;
  color: #fff;
  cursor: pointer;
}

.popup-content {
  padding: 0 !important;
}

.Modal__Content {
  margin: 0 25px;
}

.Modal__Footer {
  display: flex;
  justify-content: flex-end;
  padding: 15px 25px 25px 25px;
}

.popup-overlay {
  overflow-x: scroll;
}

.popup-content {
  width: 35% !important;
}

.rowComments {
  width: 400px;
}

.inputComments {
  height: 45px;
  border: none;
  color: gray;
  background-color: #f2f3f4;
  text-indent: 10px;
  width: 100%;
  margin: 5px;
  font-family: 'Open Sans', sans-serif;
}

.inputComments::placeholder {
  color: #b3b3b3;
  font-family: 'Open Sans', sans-serif;
  text-align: left;
}
