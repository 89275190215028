.inputfile {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
    z-index: -1;
}

.inputfile + label {
    margin-top: 6px;
    height: 45px;
    font-size: 0.875rem;
    font-weight: 400;
    color: white;
    display: inline-block;
    background-color: #132c51;
    border-radius: 0.05rem;
    padding-top: 10px;
    width: 100%;
    margin-right: 15px;
    text-align: center;
    font-family: 'Open Sans', sans-serif;
}

.inputfile + label:hover {
    background-color: white;
    color: #132c51;
    border: 1px solid #132c51;
}

.inputfile + label {
	cursor: pointer; 
}


.inputfile + label * {
	pointer-events: none;
}

.fileName {
    font-size: 12px;
    font-family: 'Open Sans', sans-serif;
}

.supportText{
    font-size: 13px;

}

.fileError{
    font-size: 12px;
    color: red;
}