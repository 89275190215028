.text-truncate {
  max-width: 10rem;
}

.nav-tabs .nav-link {
  background-color: #EEEEEE !important;
  color: #192C4E !important;
}
.nav-tabs .nav-link.active {
  background-color: #192C4E !important;
  color: #FFF !important;
}
