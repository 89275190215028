.modal-container {
    padding: 16px;
    position: relative
}

.modal-close {
    position: absolute;
    top: 8px;
    right: 8px;
    padding: 8px;
    cursor: pointer;
}

.cursor-pointer {
    cursor: pointer
}