.box-login{
    height: 100vh;
    padding-top: 10%;
    background-color: #132c51;
}

.version{
    color: white;
    font-size: 10px;
}

.error{
    color: #ffc107;
    font-size: 14px;
}
