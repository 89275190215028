#sidebar {
  position: fixed;
  width: 110px;
  height: 100%;
  left: 0px;
  background-color: #132c51;
}

#sidebar.active {
  display: initial;
  z-index: 300;
  width: 100%;
}

#sidebar ul li {
  font-size: 14px;
  ;
  color: white;
  list-style: none;
  padding: 20px 0;
  font-family: 'Open Sans', sans-serif;
  cursor: pointer;
  margin: 10px 0;
}

#sidebar ul li:hover {
  background-color: #d2d2d282;
}

.iconMenu {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2px;
  max-width: 24px;
}

.logo {
  width: 60%;
  display: block;
  margin: 40px auto;
}

.toggle-btn {
  position: absolute;
  left: 30px;
  top: 5px;
  cursor: pointer;
  color: #132c51;
}

.toggle-btn-white {
  position: absolute;
  right: 30px;
  top: 5px;
  cursor: pointer;
  color: white;
}

.toggle-btn span {
  display: block;
  width: 40px;
  text-align: center;
  font-size: 30px;
}

.toggle-btn-white span {
  display: block;
  width: 40px;
  text-align: center;
  font-size: 30px;
}

.linkMenu {
  text-decoration: none;
  color: white
}

.linkMenu:hover {
  color: white;
  text-decoration: none;
}