.widgetLg {
  flex: 2;
  -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  padding: 20px;
  background-color: #fff;
}

.widgetLgTitle {
  font-size: 22px;
  font-weight: 600;
}

.widgetLgTable{
  width: 100%;
  border-spacing: 20px;
}

.widgetLgTh{
  text-align: left;
}

.widgetLgUser{
  display: flex;
  align-items: center;
  font-weight: 600;
}

.widgetLgImg {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}

.widgetLgDate,.widgetLgAmount{
  font-weight: 300;
}

.widgetLgButton {
  padding: 5px 7px;
  border: none;
  border-radius: 10px;
}

.widgetLgButton.Approved{
    background-color: #e5faf2;
    color: #3bb077;
}
.widgetLgButton.Declined{
    background-color: #fff0f1;
    color: #d95087;

}
.widgetLgButton.Pending{
    background-color: #ebf1fe;
    color: #2a7ade;

}
