.Modal {
  padding: 0;
}

.Modal__Header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 15px;
}

.Modal__Header-title {
  padding: 15px 0 0 25px;
}

.Modal__Header-close {
  width: 50px;
  height: 50px;
  border-width: 0 0 2px 2px;
  border-color: #132C51;
  border-style: solid;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Modal__Header-close:hover {
  background-color: #132C51;
  border: 2px solid #132C51;
  color: #fff;
  cursor: pointer;
}

.popup-content {
  padding: 0 !important;
}

.Modal__Content {
  margin: 0 25px;
}

.Modal__Footer {
  display: flex;
  justify-content: flex-end;
  padding: 15px 25px 25px 25px;
}

.popup-overlay {
  overflow-x: scroll;
}