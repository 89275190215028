.box-information{
    width: 100%;
    background-color: white;
    padding: 20px;
    height: 100%;
}

.celdaText{
    color: #132c51;
    font-size: 12px;
}

.icons{
    margin: 10px;
    cursor: pointer;
}

