* {
  margin: 0px;
  padding: 0px;
}

body {
  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
}

.header {
  margin-left: 110px; /* Same as the width of the sidenav */
  background-color: white;
  height: auto;
}

.main {
  margin-left: 110px; /* Same as the width of the sidenav */
  background-color: #eeeeee;
  padding: 20px;
  height: auto;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff !important;
  background-color: #132c51 !important;
  border-color: #132c51 !important;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #132c51 !important ;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

p {
  font-family: 'Open Sans', sans-serif;
}

h1, h4 {
  font-family: 'Source Code Pro', monospace;
  color: #132c51;
  font-weight: 600 !important;
}

h4{
  font-size: 15px;
}

.gr-text {
  color: #132c51;
}

.statusText {
  color: #132c51;
  font-family: 'Source Code Pro', monospace;
  font-size: 15px;
}

.btn-primary {
  background-color: #192C4E !important;
}

.text-primary-graviti {
  color: #192C4E !important;
}

.btn-outline-primary {
  color: #192C4E !important;
  border-color: #192C4E !important;
}
.btn-outline-light {
  color: #192C4E !important;
  border-color: #192C4E !important;
}

.btn-outline-primary:hover {
  background-color: #192C4E !important;
  color: #fff !important;
}

.btn-secondary {
  color: #fff !important;
  background-color: #3A4FA6 !important;
  border-color: #3A4FA6 !important;
}

.btn-outline-secondary {
  color: #3A4FA6 !important;
  border-color: #3A4FA6 !important;
}

.badge-primary {
  background-color: #192C4E !important;
}

.badge-secondary {
  background-color: #3A4FA6 !important;
  color: #192C4E !important;
}

.badge-dark {
  background-color: #192C4E !important;
}

.badge-warning {
  background-color: #E0AA25 !important;
  color: #192C4E !important;
}


.badge-danger {
  background-color: #E02525 !important;
}

.badge-light {
  background-color: #EEEEEE !important;
}

.t-primary-2 {
  color: #173c67;
}

.t-secondary {
  color: #045d6b;
}

.t-secondary-2 {
    color: #008088;
}

.t-accent {
  color: #e0aa25;
}

.b-primary {
  background-color: #132c51 !important;
  color: white !important;
}

.b-primary:hover {
  background-color: #132c51c1 !important;
}

.b-primary-2 {
  background-color: #173c67 !important;
  color: white !important;
}

.b-primary-2:hover {
  background-color: #173c67c8 !important;
}

.b-secondary {
  background-color: #045d6b !important;
  color: white !important;
}

.b-secondary-2 {
  background-color: #008088 !important;
  color: white !important;
}

.btn-info2{
  background-color: #5499c7 !important;
  color: white !important;
}

.b-accent {
  background-color: #e0aa25 !important;
  color: white !important;
}

.b-light {
  background-color: #ffffff !important;
  color: #192C4E !important;
  border: 1px solid #192C4E !important;
}

::-webkit-input-placeholder {
  /* Edge */
  color: white;
  /* text-align: center; */
  font-family: 'Open Sans', sans-serif;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: white;
  /* text-align: center; */
  font-family: 'Open Sans', sans-serif;
}

::placeholder {
  color: white;
  /* text-align: center; */
  font-family: 'Open Sans', sans-serif;
}

.input-g {
  height: 45px;
  border: none;
  color: white;
  background-color: #ffffff75;
  text-indent: 10px;
  width: 100%;
  margin: 5px;
  font-family: 'Open Sans', sans-serif;
  text-align: center;
}

.input-g2 {
    height: 45px;
    border: none;
    color: gray;
    background-color: #a7a7a775;
    text-indent: 10px;
    width: 100%;
    margin: 5px;
    font-family: 'Open Sans', sans-serif;
}

.input-g2::placeholder {
  color: #b3b3b3;
  font-family: 'Open Sans', sans-serif;
  text-align: left;
}

.btn-1 {
  height: 45px;
  width: 100%;
  border: none;
  border-radius: 0.25rem;
  color: white;
  margin: 5px;
  font-family: 'Open Sans', sans-serif;
}

.btn-2 {
  height: 45px;
  width: 100%;
  border: none;
  border-radius: 0.25rem;
  color: white;
  font-family: Poppins, sans-serif;
  margin-bottom: 10px;
  font-weight: bold;
}

.btn-3 {
  height: 45px;
  width: 100%;
  border: none;
  border-radius: 0.25rem;
  color: #132c51;
  font-family: 'Open Sans', sans-serif;
  margin-bottom: 10px;
  border: 2px solid;
}

.btn-success{
    width: 100%;
    border: none;
    border-radius: 0.25rem;
    color:  	#00FF00;
    font-family: 'Open Sans', sans-serif;
    margin-bottom: 10px;
    border: 2px solid;
}

.btn-3:hover {
  background-color: #d7d7d7a5;
}

.table-graviti {
  background-color: white;
}

.table-graviti thead {
  font-family: 'Source Code Pro', monospace;
  text-transform: uppercase;
}

.table-products {
    background-color: white;
}

.table-products thead {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;

}

.table-products thead tr th {
    font-weight: 600;
    font-size: 16px;
    border-bottom: 2px solid #6567ad !important;
    border-top: none !important;
    text-align: center;
}

.switch-products thead tr th {
    color: #00FF00;
}

.table thead th {
  font-weight: 600;
  font-size: 14px;
  border-bottom: 2px solid #e0aa25 !important;
  border-top: none !important;
  text-align: center;
}

.table tbody td {
  font-family: 'Open Sans', sans-serif;
  font-size: 15px;
}

.textPush {
  cursor: pointer;
  text-align: center;
  vertical-align: middle !important;
}

.textNoPush {
  text-align: center;
  vertical-align: middle !important;
}

.box-table {
  padding: 2%;
  background: white;
  min-height: 640px;
}

.completedT {
  font-weight: bold;
  color: green;
}

.pendingT {
  font-weight: bold;
  color: orange;
}

.expiredT {
  font-weight: bold;
  color: red;
}

.accordion-collapse {
  background-color: #132c51;
  padding: 10px;
  border-radius: 5px;
  color: white;
  font-family: 'Source Code Pro', monospace;
  font-weight: 600;
  cursor: pointer;
  margin-bottom: 10px;
}

.accordion-collapse-default {
  background-color: #f4f4f4;
  padding: 10px;
  border-radius: 5px;
  color: gray;
  font-family: 'Source Code Pro', monospace;
  font-weight: 600;
  cursor: pointer;
  margin-bottom: 10px;
}

.headTable {
  width: 150px;
}

.box-icon {
  font-size: 10px;
  text-align: center;
  display: block;
  margin: 15px;
}

.link-donwload {
  cursor: pointer;
}

.dateInput {
  height: 40px;
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  background-color: #80808030;
  border: none;
}

.labelInput {
  margin-left: 5px;
  font-size: 12px;
  position: absolute;
  color: #173c67;
  font-family: 'Open Sans', sans-serif;
}

.isAuth {
  height: 100%;
}

.infoUser {
  font-size: 12px;
  margin-top: 20px;
  margin-right: 20px;
}

.balance {
  font-size: 13px;
  margin-top: 10px;
}

.icon-currency {
  width: 30px;
  margin-top: 12px;
}

.user-info-link {
  text-decoration: none;
  color: #132c51;
}

.Toastify__toast-container {
  margin-top: 50px;
  width: 370px !important;
}

.balance-box {
  margin-left: 20px;
}

.close-menu {
  right: 20px;
  top: 20px;
  position: absolute;
  cursor: pointer;
  display: none;
}

.open-menu {
  margin-top: 10px;
  cursor: pointer;
  display: none;
}

.text-term {
  font-size: 12px;
}

.score {
  width: 100%;
  font-size: 2.3em;
  border-radius: 10px;
  text-align: center;
  color: white;
  font-family: 'Open Sans', sans-serif;
  background-color: #132c51;
}

@media only screen and (max-width: 1025px) {
  .btn-2 {
    height: 40px;
    width: 100%;
    border: none;
    color: white;
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    margin-bottom: 10px;
  }

  .main {
    padding: 10px;
  }
  .table tbody td {
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
  }

  .table thead th {
    font-size: 12px;
  }

  .balance-box {
    text-align: end;
    width: 100%;
    margin-left: 0px;
  }

  .icon-currency {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .toggle-btn {
    display: initial;
  }

  .toggle-btn-white {
    display: initial;
  }

  #sidebar {
    display: none;
  }

  .header {
    margin-left: 0px; /* Same as the width of the sidenav */
  }

  .main {
    margin-left: 0px; /* Same as the width of the sidenav */
  }

  .balance {
    font-size: 12px;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .infoUser {
    font-size: 12px;
    margin-top: 0px;
    margin-right: 0px;
  }

  .icon-currency {
    display: none;
  }

  .main {
    padding: 5px;
  }

  .table tbody td {
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
  }

  .table thead th {
    font-size: 12px;
  }

  .btn-2 {
    height: 40px;
    width: 100%;
    border: none;
    color: white;
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    margin-bottom: 10px;
  }

  .close-menu {
    display: initial;
  }

  .open-menu {
    margin-left: 25px;
    display: initial;
  }
  .logo {
    width: 20%;
    display: block;
    margin: 40px auto;
  }

  #sidebar {
    overflow: scroll;
  }
}

@media only screen and (max-width: 580px) {
  .toggle-btn {
    display: initial;
  }

  .toggle-btn-white {
    display: initial;
  }

  #sidebar {
    display: none;
  }

  .header {
    margin-left: 0px; /* Same as the width of the sidenav */
  }

  .main {
    margin-left: 0px; /* Same as the width of the sidenav */
  }

  .balance {
    font-size: 12px;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .icon-currency {
    display: none;
  }

  .infoUser {
    font-size: 12px;
    margin: 0px;
  }

  .main {
    padding: 10px;
  }

  .table tbody td {
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
  }

  .table thead th {
    font-size: 12px;
  }

  .close-menu {
    display: initial;
  }

  .open-menu {
    margin-left: 25px;
    display: initial;
  }
  .logo {
    width: 20%;
    display: block;
    margin: 40px auto;
  }
  .logo {
    width: 20%;
    display: block;
    margin: 40px auto;
  }

  #sidebar {
    overflow: scroll;
  }
}

@media only screen and (max-width: 320px) {
  .toggle-btn {
    display: initial;
  }
  .toggle-btn-white {
    display: initial;
  }

  #sidebar {
    display: none;
  }

  .header {
    margin-left: 0px; /* Same as the width of the sidenav */
  }

  .main {
    margin-left: 0px; /* Same as the width of the sidenav */
  }

  .close-menu {
    display: initial;
  }

  .open-menu {
    display: initial;
  }

  .logo {
    width: 20%;
    display: block;
    margin: 40px auto;
  }

  #sidebar {
    overflow: scroll;
  }
}

.searchBar {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 22px;
}

.container-l {
  width: 440px;
}

.container-1 input#search {
  width: 98%;
  height: 50px;
  background: #ffffff;
  font-size: 10pt;
  color: #63717f;
  padding-left: 3.5rem;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  margin: 0 1%;
}

.container-1 input#search::-webkit-input-placeholder {
  color: #65737e;
}

.container-1 input#search:-moz-placeholder {
  /* Firefox 18- */
  color: #65737e;
}

.container-1 input#search::-moz-placeholder {
  /* Firefox 19+ */
  color: #65737e;
}

.container-1 input#search:-ms-input-placeholder {
  color: #65737e;
}

.container-1 .icon {
  position: absolute;
  top: 0%;
  margin-left: 2rem;
  margin-top: 11px;
  z-index: 1;
  color: #132c51;
}

.container-1 input#search:hover,
.container-1 input#search:focus,
.container-1 input#search:active {
  border-color: #132c51;
}

.rangeDate {
  display: flex;
  justify-content: center;
}
.container-1 .icon2 {
  position: absolute;
  top: 39%;
  margin-left: -284px;
  margin-top: 11px;
  z-index: 1;
  color: #eeeeee;
}

.datesRangeComponent {
  margin-bottom: 15px;
}

.filterBtn {
  display: flex;
  justify-content: center;
}

.qrButton{
  width: 60%;
}

.resultado-container{
    background: #fff;
    margin-top: -15px;
    height: auto;
    display: flex;
}

.resultado-title{
    margin-top: 25px;
    margin-left: 20px;
    background: white;
}

.resultado-title-movil-comprobante {
  margin-top: 25px;
  margin-left: -5px;
  background: white;
}

.resultado-title-movil {
  margin-top: 25px;
  margin-left: -7px;
  background: white;
  width: 93% !important;
}

.resultado-title h6{
  font-weight: bold;
  margin-bottom: 15px;

}

.approve-credit-container{
    background: #008088;
    height: 50px;
    align-items: center;
}

.data-credit-container{
  border: 1px solid #008088;
}

h6, h5, .checkbox-label, .span-data{
  font-family: 'Source Code Pro', monospace;
  color: #132c51;
}

.nav-container{
  height: 70px;
  background-color: #ffffff;
}

.tab-styles{
  color: #132c51 !important;
}

.comprobante-container{
  background-color: #e8eaf6;
  margin-left: -15px;
}
.comprobante-container-movil{
  background-color: #e8eaf6;
  margin-left: -9px;
}


.qr-code-containers{
  background: white;
  margin-top: -15px; ;
  height: 634px;
  padding-right: 30px !important;
}

.qr-code-containers-movil{
  background: white;
  margin-top: 100px; ;
  height: 563px;
  padding-right: 0 !important;
  padding-left: 0 !important;
  width:100vw !important;
  margin-left: 9px !important;
}
.qr-code-containers-movil-spinner{
  background: white;
  margin-top: 100px; ;
  height: 563px;
  padding-right: 0 !important;
  padding-left: 0 !important;
  width: 247px !important;
  margin-left: 4px !important;
}

.h4-movil{
  font-size: 15px;
  text-align: center;
}

.movil-titles{
  font-size: 14px;
}

.p-text-movile{
  font-size: 13px;
}

.tab-styles-qr{
  padding-left: 1px !important;
  padding-right: 2px !important;
  width: 25%;
  text-align: center;
}

.tab-content{
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.card-height{
  height: 260px;
  object-fit: contain;
}

.card-height-contrato{
  height: 134px;
  object-fit: contain;
}

.label {
  font-size: 16px;
  color: #192C4E;
}

/* Custom bootstrap styles */
.custom-checkbox > .custom-control-label.centered::before {
  left: -0.5rem
}
.custom-checkbox > .custom-control-label.centered::after {
  left: -0.5rem
}

.header-1 {
  font-size: 30px;
  font-family: Poppins;
  font-weight: bold;
}
.text {
  font-size: 18px;
  font-family: Poppins;
}
.text-blue {
  color: #192C4E
}

.switchAD{  
  position: relative;
  display: inline-block;
  margin-top: 20%;
  width: 50px;
  height: 24px;  
}
.switchAD input {
  opacity: 0;
  width: 0;
  height: 0;
}
.slidAD {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slidAD:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slidAD {
  background-color: #132c51;
}

input:focus + .slidAD {
  box-shadow: 0 0 1px #132c51;
}

input:checked + .slidAD:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
.slidAD.round {
  border-radius: 34px;
}

.slidAD.round:before {
  border-radius: 50%;
}