.progress-bar-component {
  height: 5rem;
}

.bg-secondary {
  background-color: #f4f4f4 !important;
}

.progress {
  height: 3rem;
}