.tab-section-selected {
  font-family: "Source Code Pro", monospace;
  color: #e0aa25;
  cursor: pointer;
}
.tab-section-unselected {
  font-family: "Source Code Pro", monospace;
  color: #173c67;
  cursor: pointer;
}
.box-white {
  background-color: white;
  padding: 20px;
  min-height: 650px;
}
.separator {
  color: #e0aa25;
  border-top: 2px solid;
  margin-bottom: 5%;
}
